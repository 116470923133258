import React from 'react'
import { Flex, Stack } from '@chakra-ui/react'

import { H1, H2, P } from '../components/globals'

function Section({ title, subtitle, children, ...restProps }) {
  return (
    <Flex direction="column" padding={6} maxWidth="1024px" {...restProps}>
      {(title || subtitle) && (
        <Stack spacing={0} marginBottom={6}>
          {title && <H1 as="h2">{title}</H1>}
          {subtitle && <H2 as="p">{subtitle}</H2>}
        </Stack>
      )}
      {children}
    </Flex>
  )
}

export default Section
