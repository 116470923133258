import React from 'react'
import { Heading, Text } from '@chakra-ui/react'

export function P({ children, ...restProps }) {
  return (
    <Text color="gray.600" {...restProps}>
      {children}
    </Text>
  )
}

export function H1({ children, ...restProps }) {
  return (
    <Heading as="h1" lineHeight="normal" {...restProps}>
      {children}
    </Heading>
  )
}

export function H2({ children, ...restProps }) {
  return (
    <Heading
      as="h2"
      lineHeight="normal"
      fontSize="2xl"
      fontWeight="normal"
      color="gray.600"
      {...restProps}
    >
      {children}
    </Heading>
  )
}

export function H3({ children, ...restProps }) {
  return (
    <Heading as="h3" fontSize="2xl" {...restProps}>
      {children}
    </Heading>
  )
}
