import React from 'react'
import { Flex } from '@chakra-ui/react'

function Article({ children, ...restProps }) {
  return (
    <Flex flexDirection="column" alignItems="center" {...restProps}>
      {children}
    </Flex>
  )
}

export default Article
